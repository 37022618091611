import {CRUDService} from '../../common/service';
import {Article} from '../../models/article';
import {ArticleFormData, ArticleParams} from './state';
import {RouterEnum} from '../../common/enums';

export class ArticleService extends CRUDService<Article, ArticleFormData, ArticleParams, string> {
    getNameSpace(): string {
        return RouterEnum.articles;
    }
}

const articleService = new ArticleService();

export default articleService;
