import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';

const systemConfigStateSelector = (state: AppState) => state.systemConfig;

export const selectSystemConfigStateLoading = createSelector(
    systemConfigStateSelector,
    state => state.listStatus === 'loading'
);

export const selectListSystemConfig = createSelector(
    systemConfigStateSelector,
    state => state.ids.map(id => state.entities[id])
);

export const selectSystemConfigInteracting = (id: string) => createSelector(
    systemConfigStateSelector,
    state => state.interactingIds.includes(id)
);

export const selectSystemConfigEditing = (id?: string) => createSelector(
    systemConfigStateSelector,
    state => state.formId === id
);

export const selectSystemConfigFormId = createSelector(
    systemConfigStateSelector,
    state => state.formId
)

export const selectSystemConfigFormData = createSelector(
    systemConfigStateSelector,
    state => state.entities[state.formId ?? '']
)