import axios from '../../common/axios';
import {Rest} from '../../common/models/rest';
import {DashboardSimpleCountResponse} from '../../models/dashboard/SimpleCountResponse';
import {BASE_URL} from '../../config/properties';
import {RouterEnum} from '../../common/enums';
import {DashboardMessageCountResponse} from '../../models/dashboard/MessageCountResponse';

export class DashboardService {
    statisticProducts() {
        return axios.get<Rest<DashboardSimpleCountResponse>>(`${BASE_URL}${RouterEnum.dashboard}${RouterEnum.products}`);
    }

    statisticCustomers() {
        return axios.get<Rest<DashboardSimpleCountResponse>>(`${BASE_URL}${RouterEnum.dashboard}${RouterEnum.customers}`);
    }

    statisticContactMessages() {
        return axios.get<Rest<DashboardMessageCountResponse>>(`${BASE_URL}${RouterEnum.dashboard}/messages`);
    }
}

const dashboardService = new DashboardService();

export default dashboardService;