import {CommonStatus, StateStatus} from '../../common/enums';
import {Dictionary} from '@reduxjs/toolkit';
import {PaginationMeta} from '../../common/models/pagination-meta';
import {Article} from '../../models/article';

export class ArticleFormData {
    name?: string = undefined;
    description?: string = undefined;
    content?: string = undefined;
    thumbnail?: string = undefined;
    category_id?: number = undefined;
    tags?: string[] = undefined;
    status?: CommonStatus = undefined;
}

export class ArticleParams {
    page = 1;
    limit = 40;
    search?: string = undefined;
    status: CommonStatus = CommonStatus.ACTIVE;
    category?: number = undefined;
}

export class ArticleState {
    formData = new ArticleFormData();
    formId?: number = undefined;
    formStatus: StateStatus = 'idle';

    ids: number[] = [];
    entities: Dictionary<Article> = {};
    params: ArticleParams = new ArticleParams();
    meta?: PaginationMeta = undefined;
    listLoaded: boolean = false;
    listStatus: StateStatus = 'idle';

    interactingIds: number[] = [];
}
