import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ArticleFormData, ArticleParams, ArticleState} from './state';
import {filterParams, PreparedCaseReducer, transform} from '../../common/utils';
import {FilterTransformStrategy} from '../../common/utils/filter-params';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import {Article} from '../../models/article';
import {Rest} from '../../common/models/rest';

const slice = createSlice({
    name: 'article',
    initialState: {...new ArticleState()},
    reducers: {
        submitArticleForm: (state, {payload}: PayloadAction<ArticleFormData>) => {
            state.formStatus = 'loading';
        },

        articleFormSubmitted: (state, {payload}: PayloadAction<boolean>) => {
            state.formStatus = 'idle';
        },

        singleArticleAdded: new PreparedCaseReducer<ArticleState, Article, boolean>(
            (state, {payload, meta: willUpdateForm}) => {
                state.entities[payload.id] = {
                    ...state.entities[payload.id] || {},
                    ...filterParams(payload, FilterTransformStrategy.NON_TRANSFORM)
                };
                if (!state.ids.includes(payload.id)) {
                    state.ids.push(payload.id);
                }

                if (willUpdateForm) {
                    state.formId = payload.id;
                    const formData: ArticleFormData = transform(new ArticleFormData(), payload);
                    formData.tags = payload.tags?.map(tag => tag.name);
                    state.formData = formData;
                }
            }
        ),

        articleParamsChange: new PreparedCaseReducer<ArticleState, Partial<ArticleParams> | undefined, boolean>(
            (state, {payload}) => {
                const oldParams = state.params;
                const newParams = {
                    ...state.params,
                    ...(payload ?? {})
                };
                if (!state.listLoaded || !isEqual(oldParams, newParams)) {
                    state.params = newParams;
                    state.listStatus = 'loading';
                }
            }
        ),

        listArticlesLoaded(state: ArticleState, {payload}: PayloadAction<Rest<Article>>) {
            state.ids = payload.datas.map(article => article.id);
            state.entities = keyBy(payload.datas, 'id');
            state.listLoaded = true;
            state.meta = payload.meta;
            state.listStatus = 'idle';
        },

        articleFormChanged: new PreparedCaseReducer<ArticleState, ArticleFormData, number | null>(
            (state: ArticleState, {payload, meta}) => {
                state.formData = payload;
                state.formId = typeof meta === 'undefined' ? state.formId : meta as any;
            }
        ),

        deleteArticle: (state, {payload}: PayloadAction<number>) => {
            if (!state.interactingIds.includes(payload)) {
                state.interactingIds.push(payload);
            }
        },

        articleDeleted: (state: ArticleState, {payload}: PayloadAction<number>) => {
            delete state.entities[payload];
            state.ids = state.ids.filter(id => id !== payload);
            state.interactingIds = state.interactingIds.filter(id => id !== payload);
        },

        articleDeleteFailed: (state: ArticleState, {payload}: PayloadAction<number>) => {
            state.interactingIds = state.interactingIds.filter(id => id !== payload);
        }
    }
});

export const {
    articleFormChanged,
    articleDeleteFailed,
    articleDeleted,
    deleteArticle,
    listArticlesLoaded,
    singleArticleAdded,
    articleParamsChange,
    submitArticleForm,
    articleFormSubmitted
} = slice.actions;

export const articleFormIdChange = createAction<string>('article/formIdChange');
export const loadSingleArticle = createAction<string>('article/loadSingle');

const articleReducer = slice.reducer;

export default articleReducer;
