import {all, takeLeading, call, put, select} from 'redux-saga/effects';
import {customersStatisticLoaded, productsStatisticLoaded, messagesStatisticLoaded, refreshDashboardData} from './reducer';
import {Rest} from '../../common/models/rest';
import {DashboardSimpleCountResponse} from '../../models/dashboard/SimpleCountResponse';
import dashboardService from './service';
import {DashboardMessageCountResponse} from '../../models/dashboard/MessageCountResponse';
import { selectDashboardLoaded } from './selector';
import {safeCall} from '../../common/utils';

const sagas = [
    takeLeading(refreshDashboardData, safeCall(function* () {
        const loaded = yield select(selectDashboardLoaded);
        if (loaded) {
            return;
        }
        const response: Rest<DashboardSimpleCountResponse> = yield call(dashboardService.statisticProducts);
        yield put(productsStatisticLoaded(response.data));
    })),

    takeLeading(refreshDashboardData, safeCall(function* () {
        const loaded = yield select(selectDashboardLoaded);
        if (loaded) {
            return;
        }
        const response: Rest<DashboardSimpleCountResponse> = yield call(dashboardService.statisticCustomers);
        yield put(customersStatisticLoaded(response.data));
    })),

    takeLeading(refreshDashboardData, safeCall(function* () {
        const loaded = yield select(selectDashboardLoaded);
        if (loaded) {
            return;
        }
        const response: Rest<DashboardMessageCountResponse> = yield call(dashboardService.statisticContactMessages);
        yield put(messagesStatisticLoaded(response.data));
    }))
];

function* dashboardSagas() {
    yield all(sagas);
}

export default dashboardSagas;