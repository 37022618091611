import axios from '../../common/axios';
import {Rest} from '../../common/models/rest';
import {SystemConfig} from '../../models/system-config';
import {BASE_URL} from '../../config/properties';
import {RouterEnum} from '../../common/enums';


export class SystemConfigService {
    listAll() {
        return axios.get<Rest<SystemConfig>>(`${BASE_URL}${RouterEnum.system_configs}`);
    }

    editOrCreate(id: string, data: SystemConfig) {
        return axios.put<Rest<SystemConfig>>(`${BASE_URL}${RouterEnum.system_configs}/${id}`, data);
    }
}

const systemConfigService = new SystemConfigService();

export default systemConfigService;
