import {combineReducers} from 'redux';
import auth from './features/auth/reducer';
import category from './features/category/reducer';
import product from './features/product/reducer';
import tag from './features/tag/reducer';
import article from './features/article/reducer';
import banner from './features/banner/reducer';
import customer from './features/customer/reducer';
import contactMessage from './features/contact-message/reducer';
import user from './features/user/reducer';
import systemConfig from './features/system-config/reducer';
import dashboard from './features/dashboard/reducer';

const AppReducer = combineReducers({
    auth,
    category,
    product,
    tag,
    article,
    banner,
    contactMessage,
    customer,
    user,
    systemConfig,
    dashboard
});

export default AppReducer;
