import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';

const selectDashboardState = (state: AppState) => state.dashboard;

export const selectDashboardLoaded = createSelector(
    selectDashboardState,
    state => state.loaded
);

export const selectProductStatistics = createSelector(
    selectDashboardState,
    state => state.products
);

export const selectCustomerStatistics = createSelector(
    selectDashboardState,
    state => state.customers
);

export const selectMessageStatistics = createSelector(
    selectDashboardState,
    state => state.messages
);