export enum RouterEnum {
  transactions = '/transactions',
  balances = '/balances',
  summary = '/summary',
  login = '/login',
  dashboard = '/dashboard',
  create = '/create',
  edit = '/edit',
  delete = '/delete',
  customers = '/customers',
  list = '/list',
  schedules = '/schedules',
  orders = '/orders',
  detail = '/detail',
  policies = '/policies',
  single = '/single',
  voyages = '/voyages',
  manager = '/manager',
  refunds = '/refunds',
  tickets = '/tickets',
  categories = '/categories',
  products = '/products',
  tags = '/tags',
  articles = '/articles',
  banners = '/banners',
  contact_messages = '/contact-messages',
  users = '/users',
  system_configs = '/system-configs',
}
