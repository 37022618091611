import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {filterParams} from '../../common/utils';
import {FilterTransformStrategy} from '../../common/utils/filter-params';
import keyBy from 'lodash/keyBy';
import {SystemConfig} from '../../models/system-config';
import {Rest} from '../../common/models/rest';
import {SystemConfigState} from './state';

const slice = createSlice({
    name: 'systemConfig',
    initialState: {...new SystemConfigState()},
    reducers: {
        singleSystemConfigAdded: (state, { payload }: PayloadAction<SystemConfig>) => {
            state.entities[payload.id] = {
                ...state.entities[payload.id] || {},
                ...filterParams(payload, FilterTransformStrategy.NON_TRANSFORM)
            };
            if (!state.ids.includes(payload.id)) {
                state.ids.push(payload.id);
            }
            state.interactingIds = state.interactingIds.filter(id => id !== payload.id);
            state.formId = undefined;
        },

        refreshListSystemConfig: (state) => {
            if (!state.listLoaded) {
                state.listStatus = 'loading';
            }
        },

        listSystemConfigsLoaded(state: SystemConfigState, {payload}: PayloadAction<Rest<SystemConfig>>) {
            state.ids = payload.datas.map(systemConfig => systemConfig.id);
            state.entities = keyBy(payload.datas, 'id');
            state.listLoaded = true;
            state.listStatus = 'idle';
        },

        applySystemConfig(state: SystemConfigState, {payload}: PayloadAction<SystemConfig>) {
            if (!state.interactingIds.includes(payload.id)) {
                state.interactingIds.push(payload.id);
            }
        },

        applySystemConfigFailed(state: SystemConfigState, {payload}: PayloadAction<string>) {
            state.interactingIds = state.interactingIds.filter(id => id !== payload);
        },

        systemConfigFormIdChange(state: SystemConfigState, {payload}: PayloadAction<string|undefined>) {
            state.formId = payload;
        }
    }
});

export const {
    listSystemConfigsLoaded,
    singleSystemConfigAdded,
    applySystemConfig,
    refreshListSystemConfig,
    applySystemConfigFailed,
    systemConfigFormIdChange
} = slice.actions;

const systemConfigReducer = slice.reducer;

export default systemConfigReducer;
