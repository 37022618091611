import { all, select, call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import systemConfigService from './service';
import {message} from 'antd';
import {
    refreshListSystemConfig,
    listSystemConfigsLoaded,
    applySystemConfig,
    singleSystemConfigAdded,
    applySystemConfigFailed
} from './reducer';
import {safeCall} from '../../common/utils';
import {Rest} from '../../common/models/rest';
import {selectSystemConfigStateLoading} from './selectors';
import {SystemConfig} from '../../models/system-config';

const sagas = [

    takeLeading(refreshListSystemConfig, safeCall(function* () {
        const loading = yield select(selectSystemConfigStateLoading);
        if (!loading) {
            return;
        }

        const res: Rest<SystemConfig> = yield call(systemConfigService.listAll);
        yield put(listSystemConfigsLoaded(res));
    })),

    takeEvery(applySystemConfig, function* ({payload}) {
        try {
            const response: Rest<SystemConfig>  = yield call(systemConfigService.editOrCreate, payload.id, payload);
            message.success('Config thành công');
            yield put(singleSystemConfigAdded(response.data));
        } catch (e) {
            yield put(applySystemConfigFailed(payload.id));
        }
    }),
];


function* systemConfigSagas() {
    yield all(sagas);
}

export default systemConfigSagas;
