import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DashboardState} from './state';
import {DashboardSimpleCountResponse} from '../../models/dashboard/SimpleCountResponse';
import {DashboardMessageCountResponse} from '../../models/dashboard/MessageCountResponse';

const reduceLoadedStatus = (state: DashboardState) => {
    if (state.messages && state.customers && state.products) {
        state.loaded = true;
    }
};

const slice = createSlice({
    name: 'dashboard',
    initialState: {...new DashboardState()},
    reducers: {
        productsStatisticLoaded(state, {payload}: PayloadAction<DashboardSimpleCountResponse>) {
            state.products = payload;
            reduceLoadedStatus(state);
        },

        customersStatisticLoaded(state, {payload}: PayloadAction<DashboardSimpleCountResponse>) {
            state.customers = payload;
            reduceLoadedStatus(state);
        },

        messagesStatisticLoaded(state, {payload}: PayloadAction<DashboardMessageCountResponse>) {
            state.messages = payload;
            reduceLoadedStatus(state)
        },
    }
});

export const {
    customersStatisticLoaded,
    messagesStatisticLoaded,
    productsStatisticLoaded
} = slice.actions;

export const refreshDashboardData = createAction('dashboard/refreshDashboardData');

const dashboardReducer = slice.reducer;

export default dashboardReducer;