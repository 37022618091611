import { all, select, call, put, takeLeading, takeEvery, takeLatest } from 'redux-saga/effects';
import articleService from './service';
import {message} from 'antd';
import {
    articleFormSubmitted,
    singleArticleAdded,
    submitArticleForm,
    articleFormIdChange,
    deleteArticle,
    listArticlesLoaded,
    loadSingleArticle,
    articleDeleted,
    articleDeleteFailed,
    articleFormChanged,
    articleParamsChange
} from './reducer';
import {safeCall} from '../../common/utils';
import {
    selectArticleIds,
    selectArticleListLoading,
    selectArticleOfForm,
    selectArticleParams,
    selectSingleArticle
} from './selectors';
import {pushQueryToRouter} from '../../common/actions';
import {Rest} from '../../common/models/rest';
import {Article} from '../../models/article';
import {ArticleFormData} from './state';
import history from '../../config/history';
import {RouterEnum} from '../../common/enums';

const sagas = [
    takeLeading(submitArticleForm, function* onSubmitArticleForm({payload}) {
        try {
            const existedArticle: Article = yield select(selectArticleOfForm);

            const res: Rest<Article> = existedArticle
                ? yield call(articleService.edit, existedArticle.slug, payload)
                : yield call(articleService.create, payload);

            message.success(`${existedArticle ? 'Sửa' : 'Tạo'} bài viết thành công`);

            yield all([
                put(articleFormSubmitted(true)),
                put(singleArticleAdded(res.data))
            ]);
            history.push(`${RouterEnum.articles}${RouterEnum.list}`);
        } catch (e) {
            yield put(articleFormSubmitted(false));
        }
    }),

    takeLeading(articleFormIdChange, safeCall(function* ({payload}) {
        const existedArticle: Article = yield select(selectArticleOfForm)
            if ((payload || null) === (existedArticle?.slug || null)) {
                return;
            }

            if (payload) {
                const res: Rest<Article> = yield call(articleService.single, payload);
                yield put(singleArticleAdded(res.data, true));
            } else {
                yield put(articleFormChanged(new ArticleFormData(), null));
            }
        }
    )),

    takeLatest(articleParamsChange, safeCall(function* ({meta}) {
        const loading = yield select(selectArticleListLoading);
        if (!loading) {
            return;
        }

        const params = yield select(selectArticleParams);
        const res: Rest<Article> = yield call(articleService.list, params);
        yield put(listArticlesLoaded(res));
        if (meta) {
            yield put(pushQueryToRouter(params));
        }
    })),

    takeEvery(deleteArticle, function* ({payload}) {
        try {
            const article: Article = yield select(selectSingleArticle(payload));
            yield call(articleService.delete, article.slug);
            message.success('Xóa bài viết thành công');
            yield put(articleDeleted(payload));
        } catch (e) {
            yield put(articleDeleteFailed(payload));
        }
    }),

    takeEvery(loadSingleArticle, safeCall(function* ({payload}) {
        const ids = yield select(selectArticleIds);
        if (ids.includes(payload)) {
            return;
        }
        const res: Rest<Article> = yield call(articleService.single, payload);
        yield put(singleArticleAdded(res.data));
    }))
];


function* articleSagas() {
    yield all(sagas);
}

export default articleSagas;
