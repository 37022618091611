import {StateStatus} from "../../common/enums";
import {Dictionary} from "@reduxjs/toolkit";
import {SystemConfig} from "../../models/system-config";

export class SystemConfigState {
    ids: string[] = [];
    entities: Dictionary<SystemConfig> = {};
    listLoaded: boolean = false;
    listStatus: StateStatus = 'idle';

    interactingIds: string[] = [];
    formId?: string = undefined;
}