import AppState from '../../App.state';
import {Article} from '../../models/article';
import {createSelector} from '@reduxjs/toolkit';

export const selectArticleState = (state: AppState) => state.article;

export const selectArticlePagination = createSelector(
    selectArticleState,
    state => state.meta
);

export const selectArticleParams = createSelector(
  selectArticleState,
  state => state.params
);

export const selectArticlePaginationTotal = createSelector(
    selectArticleState,
    state => state.meta?.total
);

export const selectArticleFormLoading = createSelector(
  selectArticleState,
  state => state.formStatus === 'loading'
);

export const selectArticleListLoading = createSelector(
    selectArticleState,
    state => state.listStatus === 'loading'
);

export const selectArticleFormData = createSelector(
  selectArticleState,
  state => state.formData
);

export const selectArticleFormId = createSelector(
  selectArticleState,
  state => state.formId
);

export const selectArticleOfForm = createSelector(
    selectArticleState,
    state => state.entities[state.formId ?? '']
);

export const selectListArticles = createSelector(
  selectArticleState,
  state => state.ids.map(id => state.entities[id] as Article)
);

export const selectSingleArticle = (id: number) => createSelector(
  selectArticleState,
  (state) => state.entities[id]
);

export const selectArticleIds = createSelector(
  selectArticleState,
  state => state.ids
);

export const selectOriginalCategoryOfArticleEditForm = (state: AppState) => {
    const formId = state.article.formId;
    const instance = state.article.entities[formId ?? ''];
    return state.category.entities[instance?.category_id ?? ''];
}

